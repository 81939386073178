<template>
  <div id="dashboard">
    <div class="row no-gutters">
      <div class="col-10 offset-1">
        <div class="m-card text-center ">
          <h1 class="o-heading o-heading--xl">{{ $t("unauthorized.title") }}</h1>
          <p>
            {{ $t("unauthorized.subtitle") }}
          </p>
        </div>
      </div>

      <div class="col-xl-6 offset-xl-3 col-10 offset-1">
        <div class="row mb-4 mt-3">
          <div class=" col-12 mt-2 ">
            <div class="m-card text-center">
              
              <h1 class="o-heading o-heading--xl">
                {{ $t("unauthorized.msg_1") }}
              </h1>

              <p>
                {{ $t("unauthorized.msg_2") }} 
              </p>
              
              <div class="d-flex flex-row justify-content-center">
                <b-img-lazy
                  src="../assets/Operatore.svg"
                  alt="operatore"
                  width="250"
                ></b-img-lazy>
                <b-img-lazy
                  src="../assets/Code.svg"
                  alt="code"
                  width="150"
                ></b-img-lazy>                
              </div>
              
              <div class="row no-gutters mt-3">
                <div class="col-12 pt-3">
                  <p>
                    {{ $t("unauthorized.msg_3") }} 
                    <br>
                    <strong> {{ $t("unauthorized.msg_4") }} </strong>
                  </p>
                </div>
              </div>    

              <div class="d-flex flex-column align-items-center">
                <b-img-lazy
                  src="../assets/appCheck.svg"
                  alt="appCheck"
                ></b-img-lazy>
              </div>                                    

              <div class="row no-gutters mt-3">
                <div class="col-12 pt-3">
                  <p>
                    {{ $t("unauthorized.msg_5") }}
                    <br>
                    <strong> {{ $t("unauthorized.msg_6") }} </strong>
                  </p>
                </div>
              </div>                                  

            </div>
          </div>
        </div>
      </div>

      <div class="col-xl-6 offset-xl-3 col-10 offset-1 text-center">
        <b-button variant="outline-primary" class="text-uppercase mt-3" href="/">{{ $t("generic.annulla") }}</b-button>      
      </div>
    </div>
  </div>

</template>

<script>

export default {
  name: "dashboard",
  components: {},
  props: {},
  data() {
    return {};
  },
  computed:{},
  created() {},
  methods: {}
};
</script>
